import React, { useEffect, useState } from 'react';
import {
	TextField,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	Button,
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { MainTemplate } from 'shared/MainTemplate/MainTemplate';
import handleNotification from 'helpers/handleNotification';
import { componentsServices, templateServices } from 'api/services';
import { InputAutoSize } from 'shared/InputAutoSize/InputAutoSize';
import { IResComponent } from 'api/services/components/types';
import { ITemplate, TemplateTypes } from 'api/services/templates/types';
import templateTypes from 'constants/templateTypes';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import redirect from 'helpers/redirect';
import './CreateTemplatePage.sass';
import { DEFAULT_LANGUAGE } from 'constants/project';

export const CreateTemplatePage: React.FC = () => {
	const [title, setTitle] = useState('');
	const [name, setName] = useState('');
	const [templateData, setTemplateData] = useState('');
	const [templateView, setTemplateView] = useState('');
	const [selectedTaxonomies, setSelectedTaxonomies] = useState<number[]>([]);
	const [loading, setLoading] = useState(false);
	const [templateType, setTemplateType] = useState<TemplateTypes>();
	const [hasError, setHasError] = useState(false);
	const [components, setComponents] = useState<IResComponent[]>();
	const [componentIds, setComponentIds] = useState<number[]>([]);
	const { langReducer, taxonomyReducer, userReducer } = useSelector(
		(state: AppState) => state,
	);
	const { currentLang, i18n } = langReducer;
	const { main, templates } = i18n;
	const { user } = userReducer;
	const { taxonomies } = taxonomyReducer;
	const history = useHistory();

	useEffect(() => {
		if (user) redirect(user?.role);
	}, [user?.role]);

	useEffect(() => {
		getComponents();
	}, [currentLang?.code]);

	const getComponents = async () => {
		const params = {};
		const res = await componentsServices
			.getComponents(params)
			.then((res) => res.data);
		setComponents(res.data);
	};

	const createTemplate = async (e: React.FormEvent) => {
		e.preventDefault();
		if (
			!title.trim() ||
			!name.trim() ||
			!componentIds ||
			!selectedTaxonomies ||
			!templateType
		) {
			handleNotification(main.fillFields);
			setHasError(true);
			return;
		}
		setLoading(true);
		let data: ITemplate;
		try {
			data = {
				title,
				name,
				...(templateData
					? { properties: JSON.parse(templateData || '{}') }
					: {}),
				...(templateView ? { view: JSON.parse(templateView || '[]') } : {}),
				...(templateType === 'POST' ? { taxonomyIds: selectedTaxonomies } : {}),
				type: templateType as TemplateTypes,
				components: componentIds,
			};
		} catch {
			handleNotification(main.noValidJson);
			return;
		}
		try {
			await templateServices.createTemplate(data);
			handleNotification(templates.savedTemplate, 'success');
			history.push('/templates');
		} catch (e: any) {
			handleNotification(e?.resposne?.data?.message || main.tryAgain);
			setLoading(false);
		}
	};

	return (
		<MainTemplate title={templates.createTemplate} hideBtn>
			<form onSubmit={createTemplate}>
				<TextField
					variant='outlined'
					label={templates.titleTemplate}
					className='form-field'
					value={title}
					error={hasError && !title.trim()}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<TextField
					variant='outlined'
					label={main.name}
					className='form-field'
					value={name}
					error={hasError && !name.trim()}
					onChange={(e) => setName(e.target.value)}
				/>
				<FormControl variant='outlined' className='form-field'>
					<InputLabel id='categories'>{main.components}</InputLabel>
					<Select
						label={main.components}
						value={componentIds}
						multiple
						onChange={(e) => setComponentIds(e.target.value as number[])}>
						{components?.map((i) => {
							return (
								<MenuItem key={i.id} value={i.id}>
									{i.title}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<FormControl
					className='form-field'
					variant='outlined'
					error={hasError && !templateType}>
					<InputLabel>{main.type}</InputLabel>
					<Select
						label={main.type}
						value={templateType}
						onChange={(e) => setTemplateType(e.target.value as TemplateTypes)}>
						{templateTypes(currentLang?.code || DEFAULT_LANGUAGE)?.map((i) => {
							return (
								<MenuItem key={i.value} value={i.value}>
									{i.label}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				{templateType === 'POST' && (
					<FormControl className='form-field' variant='outlined'>
						<InputLabel id='categories'>{main.taxonomies}</InputLabel>
						<Select
							labelId='categories'
							label={main.taxonomies}
							multiple
							value={selectedTaxonomies}
							onChange={(e) =>
								setSelectedTaxonomies(e.target.value as number[])
							}>
							{taxonomies?.map((i) => {
								return (
									<MenuItem key={i.id} value={i.id}>
										{i.title}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				)}
				<InputAutoSize
					value={templateData}
					placeholder={main.templateData}
					updateValue={setTemplateData}
				/>
				<InputAutoSize
					value={templateView}
					placeholder={main.displaySettings}
					updateValue={setTemplateView}
				/>
				<Button
					className='action-button'
					size='large'
					color='primary'
					type='submit'
					variant='contained'
					disabled={loading}>
					{loading ? (
						<Loader height={10} type='ThreeDots' color='#fff' />
					) : (
						templates.createTemplate
					)}
				</Button>
			</form>
		</MainTemplate>
	);
};
