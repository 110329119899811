import { LangTypes, LangActionTypes } from './types';
import { IResLang } from 'api/services/langs/types';
import { langServices } from 'api/services';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../store';
import instance from 'api/instance';
import { DEFAULT_LANGUAGE } from 'constants/project';

export const changeLang = (lang: IResLang) => {
	localStorage.setItem('lang', lang.code);
	instance.defaults.headers.common['Accept-language'] = lang.code;
	return {
		type: LangTypes.CHANGE_LANG,
		payload: { lang },
	};
};

export const getLangs =
	(): ThunkAction<void, AppState, unknown, LangActionTypes> =>
	async (dispatch) => {
		try {
			const langs: IResLang[] = await langServices
				.getLangs()
				.then((res) => res.data);
			dispatch({
				type: LangTypes.GET_LANGS,
				payload: { langs },
			});
			const currentLang = localStorage.getItem('lang');
			const lang =
				langs.find((i) => i.code === (currentLang || DEFAULT_LANGUAGE)) ||
				langs.find((i) => i.code === 'en');
			if (lang) {
				dispatch(changeLang(lang));
			}
		} catch {}
	};
