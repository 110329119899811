import _ from 'lodash';
import { FormOperator, IForm, IFormItemRef } from '../types';

interface IGetFieldStatePayload {
	hidden: boolean;
	required: boolean;
	disabled: boolean;
}

export const getRowVisibility = (
	form: IForm,
	view: string[],
	repeaterIndex?: number,
): boolean => {
	return !_.flattenDeep(view).every((i) => getFieldStates(form, i).hidden);
};

export const getFieldStates = (
	fields: IForm,
	name: string,
): IGetFieldStatePayload => {
	const item = _.get(fields, name);
	if (!item)
		return {
			hidden: false,
			required: false,
			disabled: false,
		};
	const { required, hidden, disabled } = item;
	const { hiddenRef, requiredRef, disabledRef } = item;

	return {
		hidden: hidden || checkFieldState(hiddenRef, fields, name),
		required: required || checkFieldState(requiredRef, fields, name),
		disabled: disabled || checkFieldState(disabledRef, fields, name),
	};
};

const checkFieldState = (
	formItemRef: IFormItemRef[][] | undefined,
	fields: IForm,
	name: string,
): boolean => {
	return (
		formItemRef?.some((i) =>
			i.every((ii) => getFieldState(ii, fields, name)),
		) ?? false
	);
};

const getFieldState = (
	formItemRef: IFormItemRef,
	fields: IForm,
	name: string,
): boolean => {
	let { ref } = formItemRef;
	const { value, operator, key = 'value' } = formItemRef;
	ref = ref.replace('_row_', name.split('.').slice(0, -1).join('.'));
	ref = ref.replace('_this_', name);
	const refItem = _.get(fields, ref);
	if (!refItem) return false;
	let refItemValue: any = refItem.value;

	if (key === 'index') {
		refItemValue = Number(
			ref.split('.').slice(-1).join().replace('[', '').replace(']', ''),
		);
	}

	switch (operator) {
		case FormOperator.FILLED:
			return !!refItemValue;
		case FormOperator.EMPTY:
			return !refItemValue;
		case FormOperator.EQUAL:
			return refItemValue === value;
		case FormOperator.NOT_EQUAL:
			return refItemValue !== value;
		case FormOperator.TEST:
			return new RegExp(value).test(refItemValue as string);
		default:
			return false;
	}
};
